@import "helvetica";
@import "font_awesome";
@import "bootstrap/variables";
@import "accessibility";
@import "font_awesome";
@import "justice_var";
@import "print";
@import 'https://fonts.googleapis.com/css?family=Roboto|Roboto+Condensed';
/**
 * Variables
 */


// // THEME TWO: GREEN
// $primary: #39735C;
// $accent: #6FA574;
$light: #CEDBC3;
// $background: lighten($light, 15%);
// $secondary: #3B8675;
$dark: #2C3A41;
//
$support: desaturate($accent, 5%);
$sidebar: $dark;
$sidebar-link: lighten($sidebar, 40%);


.btn-transparent {
  background: transparent;
}


/**
 * GLOBAL STYLES
 */
body {
  font-family: "Roboto", "Source Sans Pro", sans-serif;
}

#ie-warning {
  display: none;

  .modal-backdrop {
    background-color: rgba(#000, 0.7);
    pointer-events: none;
  }

  .modal {
    display: block;

    .modal-dialog {
      margin-top: 100px;
    }

    .modal-body {
      font-size: 1.2em;

      a {
        color: #FFF;
        font-weight: bold;
      }
    }
  }
}


.wrapper {
  @include background-image(45deg,#FFF, $background 10%);
}

h1,
h2,
h3,
h4 {
  font-family: "Roboto Condensed", sans-serif;
}

h2,
h3,
h4 {
  font-weight: bold;
}

h3,
h4 {
  text-transform: uppercase;
}

.main-header .logo {
  background: transparent !important; // #2c3b41 !important; // #222d32 !important;
}

.sidebar-header {
  margin: 0 auto 30px auto;
  display: block;
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;

  .logo-lg {
    display: block;
    margin: 0 auto; /* Center horizontally using margin */
    width: 145px; /* Adjust the width as needed */
    height: 40px;
    @include replace-text("/../img/logo_sidebar.png");
    background-size: 145px 40px;
    background-repeat: no-repeat;
    text-align: center; /* Center text horizontally within the block */
  }

  h2 {
    text-align: center;
    color: #FFF;
    text-transform: uppercase;
    font-size: 1em;
    margin: 5px 15px;
    @include text-shadow(0 1px 2px rgba(#000, 0.6));
  }
}


.box-header {
  p {
    color: #999;
    // line-height: 1em;
    margin: 5px 0;
  }
}

.box-body {
  background: #FFF;
}


/**
 * Basic content styles
 */


.callout {
  button, a {
    font-weight: bold;
  }
}


#content table {
  tr {
    th {
      border-right: 2px solid #FFFFFF;
      background: lighten($gray, 5%);
      text-align: center;
    }

    &:first-child th {
      border-bottom: 2px solid #FFFFFF;
      background: $gray;
    }

    td {
      border-right: 1px solid $gray;
    }

    td:last-child,
    th:last-child {
      border-right: none;
    }
  }
}
/**
 * Content styles
 */
.content-wrapper {
  background-color: transparent !important;

  h1 {
    font-size: 240%;
    // border-bottom: 2px solid #CCC;
    padding-bottom: 7px;
    margin: 0 0 20px 0;
    color: $primary; // #777;
  }
}


.page-description {
  &.callout-default {
    background: darken($background, 5%);
    border-color: darken($background, 10%);

    i {
      color: darken($background, 20%);
    }
  }
}

/**
 * Nav styles
 */
.navbar.dashboard-nav {
  // @include background-image(linear-gradient(to right, $support, darken($support, 10%)));
  // border-bottom: 4px solid darken($support, 10%) !important;
  // border-bottom: 4px solid lighten($primary, 5%) !important;

  background: transparent !important; // theme override

  a {
    font-weight: bold;

    i {
      font-size: 1.2em;
      font-weight: normal;
      margin-right: 3px;
    }
  }

  h1.dash-title {
    font-size: 28px;
    font-weight: bold;
    color: #FFF;
    margin: 0 15px;
    line-height: 50px;
    @include text-shadow(0 1px 2px rgba(#000, 0.6));
  }
}

.main-sidebar {
  background: $sidebar !important;
  // @include background-image(45deg, darken($sidebar, %), $sidebar 80px);

  .sidebar {
    margin-top: 80px;

    // theme override preventing jittering
    .sidebar-menu {
      overflow: hidden;
      &:hover { overflow: hidden; }
    }
  }

  .nav-section-header {
    padding: 7px 20px;
    margin-top: 20px;
    margin-left: 7px;
    color: lighten($light, 30%);
    font-weight: bold;
    font-size: 15px;
    letter-spacing: -0.5px;
    text-transform: uppercase;
    @include text-shadow(0 2px 3px rgba(#000, 0.5));

    i {
      margin: 0 8px;
    }
  }

  .treeview-menu {
    background: transparent !important;

    li {
      position: relative;

      // &:before {
      //   font-family: 'FontAwesome';
      //   content: "\F105";
      //   color: $sidebar-link;
      //   position: absolute;
      //   top: .7em;
      //   left: 15px;
      //   @include single-transition(all, 0.3s);
      // }

      &:hover:before, &.active:before {
        left: 23px;
        color: #FFF;
      }

      a {
        display: block;
        white-space: normal;
        line-height: auto;
        margin: 5px 0 5px 20px;
        position: relative;
        left: 0;
        background: lighten($sidebar, 5%);
        @include border-top-left-radius(7px);
        @include border-bottom-left-radius(7px);
        padding: 10px;
        @include single-transition(all, 0.3s);
        font-weight: bold;
        color: $sidebar-link;
        font-size: 1.1em;



        &:hover {
          color: #FFF;
          left: 10px;
          background-color: lighten($sidebar, 10%);
        }

        i {
          display: none;
          @include single-transition(all, 0.3s);
          position: relative;
          left: 0;
          color: $sidebar-link;
        }

        &:hover i {
          // hover effects for carats
          position: relative;
          left: 8px;
          color: #fff;
        }
      }

      &.active a {
        left: 10px;
        background-color: $primary;
        i {
          position: relative;
          left: 8px;
          color: #fff;
        }
      }
    }
  }
}
// floating feedback link
.global-feedback {
  width: 230px;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;

  a {
    @include single-transition(all, 0.3s);
    display: block;
    padding: 15px;
    line-height: 1em;
    background: lighten($sidebar, 10%);
    color: #FFF;
    font-weight: bold;
    overflow: hidden;

    i {
      @include single-transition(all, 0.3s);
      position: relative;
      left: 85px;
    }

    .feedbacktxt {
      @include single-transition(all, 0.3s);
      position: relative;
      left: 100px;
      opacity: 0.0;
    }

    &:hover {
      background: $support;

      i {
        left: 25px;
      }

      .feedbacktxt {
        left: 0;
        opacity: 1.0;
      }
    }
  }

  &.standalone {
    overflow: hidden;
    margin: 10px;


      a {
        border: 3px solid #f7f7f7;
        @include border-radius(10px);
        background: $gray;
        width: 80px;
        overflow: hidden;

        .feedback-wrapper {
          width: 250px;

          i {
            left: 10px;
          }

          .feedbacktxt {
            width: 140px;
            overflow:hidden;
          }
        }

        &:hover {
          background: $support;
          width: 200px;
        }
      }
    }
  }

/**
 * Upload interface
 */
#content.upload-content {
  width: 100%;
  border: 5px solid transparent;
  margin-top: 15px;
  min-height: 200px;
  position: relative;
  @include border-radius(20px);

  .upload-msg {
    display: none;
  }

  // .alert {
  //   max-width: 800px;
  //   margin: 10px auto;
  // }
}
/**
 * Report upload styles
 */
.upload-btn, .upload-drop-btn {
  .dragging {
    display: none;
  }

  .alert.security-notice {
    font-size: 15px;
    @include border-radius(15px);
    margin: 2em 0;
    padding: 15px 15px 15px 100px;
    text-align: left;
    position:relative;
    color: #777;

    &:before {
      content: "\F023";
      font-family: 'FontAwesome';
      font-size: 70px;
      position: absolute;
      left: 27px;
      top: 30px;
      color: #999;
    }
  }

  .uploading {
    display: none;
  }
  border: 2px solid transparent;
  @include border-radius(10px);
  text-align: center;
  margin: 100px auto 1em auto;
  // height: 80px;
  width: 600px;
  font-size: 2em;


  div.file-upload {
    // avoid bubbling drag/drop events
    * {
      pointer-events: none;
    }
    @include single-transition(all, 0.3s);
    position: relative;
    font-weight: bold;
    padding: 30px;
    border: 0;
    text-align: center;
    // font-size: 20px;
    color: #777;
    background: #DDD;
    overflow: hidden;
    @include border-radius(15px);

    &>div {
      display: table;
      width: 100%;
      table-layout: fixed;

      &>* {
        display:table-cell;
        vertical-align:middle;
        text-align:center;
      }
    }

    .default { display: table; }
    .dragging { display: none; }
    .uploading { display: none;   }

    &:hover {
      background: $vibrant;
      color: #FFF;
      cursor: pointer;
    }

    &:hover.uploading {
      background: transparent;
    }

    span {
      margin-right: 10px;
      width: 80%;
    }

    i.fa {
      vertical-align: middle;
      font-size: 3em;
      margin: 5px 0;
    }
  }

  .msg {
    color: #AAA;
    font-size: 20px;
    font-weight: bold;
    display: none;
  }

  &.error {
    & > button {
      display: none;
    }

    .error-msg {
      display: block;
      color: red;

      button {
        margin-left: 20px;
      }
    }
  }
}

.upload-dragging .file-upload {
  // border: 2px dashed #AAA;

  background: $vibrant !important;
  color: #FFF !important;

  .default {
    display: none !important;
  }

  .dragging {
    display: table !important;
  }

  .uploading {
    display: none !important;
  }

  .upload-msg {
    display: block;
  }
}

.upload-processing .file-upload {
  // border: 2px dashed #AAA;

  background: $vibrant !important;
  color: #FFF !important;

  .default {
    display: none !important;
  }

  .dragging {
    display: none !important;
  }

  .uploading {
    display: table !important;
  }
}

#content.uploading .upload-btn {
  border-color: transparent;

  .default {
    display: none;
  }

  .dragging {
    display: none;
  }

  .uploading {
    display: block;
  }

  .upload-btn .uploading-msg {
    display: block;
  }
}
/**
 * Report correction table/interface
 */
// .nav-tabs {
//   display: none;
// }

.error-nav {
  margin-bottom: 15px;
}

#report-status {
  font-size: 15px;
  display: none;
}

.error-status {
  font-size: 17px;
  margin: 0 10px;
}

.tabs {
  margin-bottom: 20px;
}

div.dataTables_wrapper {
  .loading-spinner {
    position: absolute;
    z-index: 9999;
    background: #DDD;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    display: none;
    text-align: center;

    i {
      margin-top: 150px;
      font-size: 50px;
      color: #777;
    }
  }

  &.loading {
    .loading-spinner {
      display: block;
    }

    .row {
      visibility: hidden;
    }
  }
  width: 100%;
  position: relative;

  input {
    font-size: 13px;
  }

  .static-rows .static-cell,
  .static-rows .static-row-header {
    padding: 0;
    overflow: hidden;

    table {
      margin: 0;
    }

    td,
    th {
      background: #DDD;
      border-right: 1px solid #FFF;
      overflow: hidden;
      margin: 0;
      padding: 4px;
      text-align: center;
      word-wrap: break-word;
      line-height: 1em;
      vertical-align: middle;
    }
  }
  // .col-xs-2 {
  //   padding: 0;
  // }
  // .col-xs-10 {
  //   padding: 0;
  // }
  .static-cell {
    table {
      width: 100%;

      td,
      th {
        width: 50%;
        margin: 0;

        &:nth-child(1) {
          background: transparent;
        }
      }
    }
  }

  .static-cols {
    position: relative;
    overflow: hidden;
    // overflow-y: scroll;
    height: 300px;
    pointer-events: none;

    .static-col-cells {
      position: relative;
      height: 500px;

      table {
        width: 100%;

        td {
          position: absolute;
          width: 50%;
          left: 0;

          &:nth-child(2) {
            left: 50%;
            text-align: center;
            font-weight: bold;
            color: #777;
          }
        }

        tr {
          width: 100%;
        }
      }

      button {
        pointer-events: all;
        border: 0;
        background: transparent;
        display: none;

        .num-errors {
          font-weight: bold;
          font-size: 17px;
          color: red;
        }

        i {
          margin-left: 5px;
          font-size: 20px;
          color: red;
        }
      }
    }
  }

  .error-flag {
    width: 50px;
    position: absolute;
    left: -100px;
    top: 0;

    i {
      font-size: 20px;
    }
  }

  .tbody {
    max-height: 300px;
    margin-left: 0;
    overflow: scroll;

    td {
      padding: 8px 4px;
    }

    .mo-actions {
      min-width: 200px;
    }
  }
}
/**
 * Global filter styles (Main template)
 */
.layout-top-nav {
  .filter-nav {
    margin-left: 30px;

    li.filter-select {
      width: 250px;
      padding: 7px 0;
    }

    li.date-select {
      padding: 7px 0;
    }

    label {
      color: #FFF;
      margin-right: 10px;
    }

    .form-group {
      margin: 0;
    }

    .form-control {
      display: inline-block;
      width: auto;
    }
  }
}
/**
 * Global filters
 */
section.global-filters-header {
  // position: absolute;
  // width: 400px;
  // right: 15px;
  // top: 55px;
  // text-align: right;
  // padding: 15px;
  // float: right;
  // overflow: hidden;
  // z-index: 500;
  .global-filters {
    float: right;

    .filter-item {
      margin-left: 10px;
    }

    .filter-changes {
      margin-right: 10px;
    }

    .pinned {
      float: none;
      position: fixed;
      right: 16px;
      top: 9px;
      z-index: 99999;

      .dropdown-menu {
        @include box-shadow(0 2px 4px rgba(#000, 0.3));
      }
    }

    .timeline {
      display: inline-block;
      margin-bottom: 0;
    }

    .filter {
      display: inline-block;

      .filter-select {
        display: inline-block;
        min-width: 150px;
        margin-right: 10px;
      }

      .multiselect-native-select {
        margin-right: 10px;
      }
    }

    .data-filter {
      display: inline-block;
      width: auto;
      margin-right: 7px;
    }

    .report-download-btn {
      display: inline-block;
      margin-left: 7px;
      font-size: 14px;
    }
    // Dropdown for advanced filters
    .filter-menu {
      padding: 7px 0;

      i.filter-off {
        display: inline-block;
        color: #BBB;
      }

      i.filter-on {
        display: none;
      }

      .on {
        i.filter-off {
          display: none;
        }

        i.filter-on {
          color: $primary;
          display: inline-block;
        }
      }
    }
  }
  // CBO SELECTION MODAL
  #filter-by-cbo-selection {
    .modal-body {
      padding: 15px 50px;
      max-height: 400px;
      overflow-y: scroll;
    }
  }
}

.daterangepicker {
  @include box-shadow(0 3px 5px #AAA);
}
/**
 * Month range picker
 */
.mrp-container {
  margin-top: 10px;
}

.mrp-icon {
  border: solid 1px #ddd;
  border-radius: 5px 0 0 5px;
  color: #40667A;
  background: #eee;
  padding: 7px;
  margin-right: 0;
}

.mrp-monthdisplay {
  display: inline-block!important;
  border: solid 1px #ddd;
  padding: 5px 12px 5px 8px;
  border-radius: 0 5px 5px 0;
  background-color: #fff;
  cursor: pointer;
  margin-left: -5px;
}

.mrp-lowerMonth,
.mrp-upperMonth {
  color: #40667A;
  font-weight: bold;
  font-size: 11px;
  text-transform: uppercase;
}

.mrp-to {
  color: #aaa;
  margin-right: 0;
  margin-left: 0;
  font-size: 11px;
  text-transform: uppercase;
  /* background-color: #eee; */
  padding: 5px 3px;
}

.mpr-calendar {
  display: inline-block;
  padding: 3px 5px;
  border-right: solid #999 1px;
}

.mpr-calendar::last-child {
  border-right: none;
}

.mpr-month {
  padding: 20px;
  text-transform: uppercase;
  font-size: 12px;
}

.mpr-calendar h5 {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

.mpr-selected {
  background: rgba(64, 102, 122, 0.50);
  color: #fff;
}

.mpr-month:hover {
  border-radius: 5px;
  box-shadow: 0 0 0 1px #ddd inset;
  cursor: pointer;
}

.mpr-selected.mpr-month:hover {
  border-radius: 0;
  box-shadow: none;
}

.mpr-calendarholder .col-xs-6 {
  max-width: 250px;
  min-width: 250px;
}

.mpr-calendarholder .col-xs-1 {
  max-width: 150px;
  min-width: 150px;
}

.mpr-calendarholder .btn-info {
  background-color: #40667A;
  border-color: #406670;
  width: 100%;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 10px;
  padding: 10px 0;
}

.mpr-quickset {
  color: #666;
  text-transform: uppercase;
  text-align: center;
}

.mpr-yeardown,
.mpr-yearup {
  margin-left: 5px;
  cursor: pointer;
  color: #666;
}

.mpr-yeardown {
  float: left;
}

.mpr-yearup {
  float: right;
}

.mpr-yeardown:hover,
.mpr-yearup:hover {
  color: #40667A;
}

.mpr-calendar:first .mpr-selected:first {
  background-color: #40667A;
}

.mpr-calendar:last .mpr-selected:last {
  background-color: #40667A;
}

.popover {
  max-width: 1920px!important;
  z-index: 9999999;
}

.mpr-accept {
  //  margin-top: 50px;
  width: 100%;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  padding: 10px 0;
}

/**
 * Cohort / ingester
 */

// ingester
.modal-cohort {

  .modal-dialog {
    width: 90vw;

    .modal-body {
      width: 100%;
      height: 90vh;

      iframe {
        border: 1px solid #ccc;
      }
    }
  }

}
