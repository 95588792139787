@import "helvetica";
@import "bootstrap/variables";

@import "justice_var";

/**
 * Print style overrides for all sections.
 * other default theme styles in print.less
 */

@media print {

    .modal {
      display: none;
    }
    .box {
      border: 1px solid #CCC;
      padding: 10px;

      &.box-error, &.box-loading {
        display: none;
      }
    }

    .content-headers {
      display: block !important; // AdminLTE styles hide this by default
    }

    // CARDS
    .card-container {
      margin-top: 30px;

      .flip-container {
        .flipper {

          &, & > .front, & > .back, & .chart {
            height: auto !important;
          }

          transform-style: flat;
          transform: rotateX(0deg) !important;
          .front, .back {
            position: relative;
          }
        }

        .back {
          transform: rotateX(0deg) !important;
        }
      }

      .box-tools {
        display: none;
      }

      .feedback-wrap {
        display: none;
      }

      table {
        border: 1px solid #BBB;

        td, th {
          border-color: #BBB !important;
          border-width: 1px !important;
          padding: 4px 8px !important;
        }
        th {
          background: #EEE !important;
        }

      }

    }
    // end card-container

    .nav-tabs, .tooltip {
      display: none;
    }

    footer {
      display: none;
    }

}
