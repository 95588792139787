// bootstrap colors
//Primary
$light-blue: #3c8dbc;
//Danger
$red: #dd4b39;
//Success
$green: #00a65a;
//Info
$aqua: #00c0ef;
//Warning
$yellow: #f39c12;
$blue: #0073b7;
$navy: #001F3F;
$teal: #39CCCC;
$olive: #3D9970;
$lime: #01FF70;
$orange: #FF851B;
$fuchsia: #F012BE;
$purple: #605ca8;
$maroon: #D81B60;
$black: #111;
$gray: #d2d6de;

// THEME ONE: BLUE
// $support: #3c8dbc;
// $sidebar-link: #8aa4af;
// $background: #ecf0f5;
$sidebar: #2c3b41;
// $primary: $support;
// $light: $sidebar-link;

// // THEME TWO: COOLORS


$support: #114b5f;
$primary: #028090;
$color3: rgba(228, 253, 225, 1);
$color4: rgba(69, 105, 144, 1);
$accent: rgba(244, 91, 105, 1);
$vibrant: rgba(100, 215, 231, 1);
$background: #ecf0f5;
$dark: #383838;




// MISCELLANEOUS VARIABLES

$justice-title: 'Pacifico', cursive;
$p_teal: #358090;
$fb-primary-color: #fff;
$fb-primary-bg: #3b5998;
$fb-primary-border: #3b5998;

$goog-primary-color: #fff;
$goog-primary-bg: #4285F4;
$goog-primary-border: #4285F4;

$primary-color: #D6757E;

$dark-bg: #383838;
$error-text: #d9534f;

$form-border: #181818;


// MIXINS -- we used to get these for free from Compass

@mixin replace-text($image, $position: 100%, $size: auto) {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    background: url($image) no-repeat;
    background-position: $position;
    background-size: $size;
  }
  
  
@mixin single-transition($property, $duration, $ease: ease) {
transition: $property $duration $ease;
}
  
@mixin filter($filters) {
    -webkit-filter: $filters;
    filter: $filters;
  }

@mixin border-radius($radius) {
-webkit-border-radius: $radius;
-moz-border-radius: $radius;
border-radius: $radius;
}

@mixin transition-property($properties...) {
    transition-property: $properties;
  }
  

@mixin transition-duration($duration) {
transition-duration: $duration;
}
  
@mixin transition-property($properties...) {
    transition-property: $properties;
  }

@mixin backface-visibility($visibility) {
-webkit-backface-visibility: $visibility;
backface-visibility: $visibility;
}
  
@mixin border-bottom-right-radius($radius) {
    border-bottom-right-radius: $radius;
  }

@mixin border-bottom-left-radius($radius) {
    border-bottom-left-radius: $radius;
}

@mixin border-top-left-radius($radius) {
    border-top-left-radius: $radius;
}

@mixin border-top-right-radius($radius) {
    border-top-right-radius: $radius;
}


@mixin box-shadow($shadows...) {
    -webkit-box-shadow: $shadows;
    -moz-box-shadow: $shadows;
    box-shadow: $shadows;
  }

@mixin single-box-shadow($shadows...) {
    -webkit-box-shadow: $shadows;
    -moz-box-shadow: $shadows;
    box-shadow: $shadows;
}

@mixin background-image($angle, $start-color, $end-color, $start-percent: 0%, $end-percent: 100%) {
    background-image: -webkit-linear-gradient($angle, $start-color $start-percent, $end-color $end-percent);
    background-image: -moz-linear-gradient($angle, $start-color $start-percent, $end-color $end-percent);
    background-image: -o-linear-gradient($angle, $start-color $start-percent, $end-color $end-percent);
    background-image: linear-gradient($angle, $start-color $start-percent, $end-color $end-percent);
  }
  
  
  

@mixin text-shadow($shadows...) {
    text-shadow: $shadows;
  }
  