.text-size-btns {
  button.small {
    font-size: 12px;
    line-height: 18px;
  }

  button.large {
    font-size: 20px;
    line-height: 18px;
  }
}

// LARGE FONT STYLES
body.text-large {
  font-size: 170%;

  .sidebar-menu {
    .nav-section-header {
      font-size: 110%;
    }
    & li a {
      font-size: 100%;
    }
  }
}
